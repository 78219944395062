import React, {useEffect, useState, useContext, useCallback} from 'react';
import {AxiosResponse} from 'axios';
import {useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {
  ClaimListResponse,
  ClaimListItemViewModel,
  Pagination,
  Order,
  TableColumn,
} from '../../../types';
import {api, getCached} from '../../../utils/Request';
import getClaimFrequency from '../../../utils/ClaimFrequencyUtils';
import {formatDate} from '../../../utils/DateUtils';
import {getDisplayAmountValue} from '../../../utils/AmountUtils';
import {getGSTType, displayClaimType} from '../../../components/Claim/claimListingUtils';
import {Amount} from '../../../components/Amount';
import {ClaimStatus} from '../../../components/Status';
import {SimpleDataTable} from '../../../components/SimpleDataTable';

const defaultOrder: Order = {orderBy: 'endAt', orderByDirection: 'desc'};

const DealClaimsTabContent = () => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const {id = ''} = useParams<{id: string}>();
  const [rows, setRows] = useState<ClaimListItemViewModel[]>([]);
  const [pagination, setPagination] = useState(Consts.DefaultPagination);
  const [order, setOrder] = useState<Order>(defaultOrder);

  interface FetchClaimsParams extends Pagination, Order {}

  const fetchClaims = useCallback(
    async (params: FetchClaimsParams) => {
      try {
        showLoading();
        const response: AxiosResponse<ClaimListResponse> = await getCached(
          api(Consts.Api.DealAgreementClaims.replace(':id', id)),
          {
            params,
          }
        );

        const {data, ...responsePagination} = response.data;
        setRows(data);
        setPagination(responsePagination);
        alertService.clear(defaultAlertId);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    },
    [id, hideLoading, showLoading, setRows, setPagination]
  );

  useEffect(() => {
    fetchClaims({...Consts.DefaultPagination, ...defaultOrder});
  }, [fetchClaims]);

  const handlePagination = (newPagination: Partial<Pagination>) => {
    setPagination({...pagination, ...newPagination});
    fetchClaims({...pagination, ...newPagination, ...order});
  };

  const handleOrderBy = (orderBy: string) => {
    setOrder({orderBy, orderByDirection: order.orderByDirection});
    fetchClaims({...pagination, ...order, orderBy});
  };

  const columns: TableColumn<ClaimListItemViewModel>[] = [
    {
      id: 'claimVendorName',
      label: 'Claim Vendor',
      minWidth: 80,
      sortable: true,
    },
    {
      id: 'description',
      label: 'Deal Description',
      minWidth: 150,
      sortable: true,
      render: (rowData) => (
        <Typography sx={{wordBreak: 'break-word'}}>{rowData.description}</Typography>
      ),
    },
    {
      id: 'owner',
      label: 'Owner',
      minWidth: 80,
      sortable: true,
      render: (rowData) => <Typography>{rowData.ownedByStaffName}</Typography>,
    },
    {
      id: 'type',
      label: 'Type',
      render: displayClaimType,
    },
    {id: 'id', label: 'Claim ID'},
    {
      id: 'startAt',
      label: 'Claim Period Start Date',
      minWidth: 80,
      render: (rowData) => formatDate(rowData.startAt),
      sortable: true,
    },
    {
      id: 'endAt',
      label: 'Claim Period End Date',
      minWidth: 80,
      render: (rowData) => formatDate(rowData.endAt),
      sortable: true,
    },
    {
      id: 'claimFrequency',
      label: 'Claim Frequency',
      minWidth: 120,
      render: (rowData) =>
        getClaimFrequency(
          rowData.agreementType,
          rowData.claimFrequency,
          rowData.claimDaysAfterExpiry
        ),
    },
    {
      id: 'totalClaimAmount',
      label: 'Claim Amount',
      render: (rowData, _i?: number, _isFirstAdj?: boolean, totalClaimAmount?: number) => {
        const postfixDifferent = rowData.totalClaimAmount !== rowData.accrualAmount ? ' *' : '';
        const postfixGstType = getGSTType(rowData);
        return (
          <Stack spacing={2} data-testid="claim-amount">
            <Amount
              value={rowData.totalClaimAmount}
              prefix={`${postfixDifferent}$`}
              postfix={`${postfixGstType}`}
            />
            {typeof totalClaimAmount === 'number' ? (
              <Typography
                sx={{fontStyle: 'italic', whiteSpace: 'nowrap', color: 'rgba(112, 112, 112, 1)'}}
              >
                Total {getDisplayAmountValue(totalClaimAmount, '$', postfixGstType, 2)}
              </Typography>
            ) : null}
          </Stack>
        );
      },
    },
    {
      id: 'raisedAt',
      label: 'Claim Raised Date',
      minWidth: 80,
      sortable: true,
      render: (rowData) => formatDate(rowData.raisedAt, false) || '-',
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 80,
      sortable: true,
      render: (rowData) => <ClaimStatus status={rowData.status} />,
    },
    {
      id: 'invoiceNumber',
      label: 'Invoice Number',
      minWidth: 80,
      sortable: true,
    },
    {
      id: 'promotionIdAndSupplierNumber',
      additionalLine: 1,
      colspanLeft: 1,
      colspan: 12,
      isShown: (rowData) => !!rowData.promotionId || !!rowData.supplierApprovalNumber,
      render: (rowData) => (
        <div>
          {rowData.promotionId ? <div>{`Promo ID: ${rowData.promotionId}`}</div> : null}
          {rowData.supplierApprovalNumber ? (
            <div>{`Supplier Approval Number: ${rowData.supplierApprovalNumber}`}</div>
          ) : null}
        </div>
      ),
      style: {
        fontStyle: 'italic',
        opacity: 0.7,
        paddingTop: 0,
      },
    },
  ];

  return (
    <SimpleDataTable
      columns={columns}
      rows={rows}
      pagination={pagination}
      onChangePage={handlePagination}
      onChangePageSize={handlePagination}
      onOrderBy={(o) => handleOrderBy(o.orderBy)}
      sortOrderBy={order.orderBy}
      sortOrder={order.orderByDirection}
      highlightColumn={order.orderBy}
      darkTheme
    />
  );
};

export default DealClaimsTabContent;
