import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import type {SxProps} from '@mui/material';
import {getDisplayAmountValue} from '../../utils/AmountUtils';
import Consts from '../../app/Consts';
import {ShadowBox} from '../BaseStyle/Containers';

const PREFIX = 'SplitIconAmountComponent';

const classes = {
  root: `${PREFIX}-root`,
  contentFlex: `${PREFIX}-contentFlex`,
  amount: `${PREFIX}-amount`,
};

const Root = styled(ShadowBox)(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3.125rem 2.75rem',
    margin: '0.9375rem 0',
    minWidth: 'fit-content',
  },
  [`& .${classes.contentFlex}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: '0.625rem',
  },
  [`& .${classes.amount}`]: {
    fontSize: '2.5rem',
  },
}));

type Props = {
  icon: React.ReactNode;
  label: string;
  amount: number | null;
  taxAmount?: number;
  gstType: string | null;
  sx?: SxProps;
};

const SplitIconAmountComponent: FC<Props> = ({
  icon,
  label,
  amount,
  taxAmount = 0,
  gstType,
  sx = {},
  ...rest
}) => {
  const postfix =
    gstType === Consts.GstTypeEnum.Free
      ? ' GST free'
      : gstType === Consts.GstTypeEnum.Exclusive
      ? ' ex.'
      : gstType === Consts.GstTypeEnum.Inclusive
      ? ' inc.'
      : '';
  return (
    <Root className={classes.root} {...rest} sx={{backgroundColor: 'white', ...sx}}>
      <div>{icon}</div>
      <div className={classes.contentFlex}>
        <span>{label}</span>
        <span className={classes.amount}>{getDisplayAmountValue(amount, '$', postfix)}</span>
        {gstType === Consts.GstTypeEnum.Inclusive ? (
          <span>{getDisplayAmountValue(taxAmount, '$')} GST</span>
        ) : null}
      </div>
    </Root>
  );
};

export {SplitIconAmountComponent};
