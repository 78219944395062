import {get, api} from './Request';
import {alertService, defaultAlertId} from '../app/AlertService';

/**
 * Downloads a file from the specified path
 * @param path - API path to download the file from
 * @param options - Additional options for the download
 * @returns Promise that resolves when download completes
 */
export const downloadFile = async (
  path: string,
  options?: {
    disableAlert?: boolean;
    fileName?: string;
  }
): Promise<void> => {
  try {
    const response = await get(api(path), {
      responseType: 'blob',
    });

    const data = response.data;
    const link = document.createElement('a');
    const url = URL.createObjectURL(new Blob([data]));

    link.href = url;

    // Use provided filename or extract from Content-Disposition header
    if (options?.fileName) {
      link.download = options.fileName;
    } else if (response.request && response.request.getResponseHeader) {
      const contentDisposition = response.request.getResponseHeader('Content-Disposition');
      if (contentDisposition) {
        link.download = contentDisposition.split('filename="')[1]?.split('";')[0] || 'download';
      } else {
        link.download = 'download';
      }
    } else {
      link.download = 'download';
    }

    // Append to body, click and remove
    document.body.appendChild(link);
    link.click();

    // Clean up
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 100);
  } catch (error: any) {
    if (!options?.disableAlert) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    }
  }
};
