import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {ShadowBox} from '../BaseStyle';
import IconLabelButton from '../Button/IconLabelButton';

const StyledBox = styled(ShadowBox)<{flexibleHeight?: boolean}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  font-size: 18px;
  color: #000000;
  > span {
    &:last-child {
      color: #626262;
    }
  }
`;

const WrapperBox = ({
  flexibleHeight,
  ...props
}: {flexibleHeight?: boolean} & React.ComponentProps<typeof StyledBox>) => (
  <StyledBox
    sx={{height: flexibleHeight ? '100%' : '7rem', minHeight: flexibleHeight ? '7rem' : 'auto'}}
    {...props}
  />
);

const IconLabelGroup = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 0.625rem;
  svg {
    width: 2.8125rem;
    margin-right: 1.25rem;
  }
`;

type Props = {
  leftLabel: string;
  rightLabel?: string | React.ReactNode;
  onButtonClick?: () => void;
  buttonLabel?: string;
  buttonIcon?: React.ReactNode;
  icon?: React.ReactNode;
  flexibleHeight?: boolean;
  style?: React.CSSProperties;
  rightLabelFullWidth?: boolean;
};

const SplitTextComponent: FC<Props> = ({
  leftLabel,
  rightLabel,
  onButtonClick,
  buttonLabel,
  buttonIcon,
  icon,
  flexibleHeight = false,
  rightLabelFullWidth = false,
  ...props
}) => (
  <WrapperBox flexibleHeight={flexibleHeight}>
    <IconLabelGroup>
      {icon}
      {leftLabel}
    </IconLabelGroup>
    <Box sx={{width: rightLabelFullWidth ? '100%' : 'auto'}}>
      {buttonIcon ? (
        <IconLabelButton
          startIcon={false}
          icon={buttonIcon}
          label={buttonLabel}
          onClick={onButtonClick}
          {...props}
        />
      ) : (
        rightLabel
      )}
    </Box>
  </WrapperBox>
);

export {SplitTextComponent};
